<template>
  <b-card title="Laporan Pembayaran Siswa">
    <b-row>
      <b-col md="12">
        <validation-observer ref="accountRules" tag="form">
          <b-form class="mt-1" @submit.prevent>
            <b-row>
              <b-col cols="12" md="4">
                <validation-provider #default="{ errors }" name="nisn" rules="required">
                  <b-form-group label="NISN" label-for="nisn" :state="errors.length > 0 ? false : null">
                    <v-select id="nisn" v-model="dataParent.nisn" :reduce="(nisn) => nisn.nisn" placeholder="Pilih Siswa"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listSiswa" label="name" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
                    @click="GetAllData"><feather-icon icon="SearchIcon" />
                    View
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>

          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
    <table class="table" id="generatetable">
      <thead>
        <tr>
          <th>No</th>
          <th>Invoice</th>
          <th>NISN</th>
          <th>Nama</th>
          <th>Nama Pembayaran</th>
          <th>Tahun Ajaran</th>
          <th>Periode</th>
          <th>Nominal</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  // BAvatar,
  BButton,
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { codeAdvance } from './code'

import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

import axios from 'axios';
import $ from 'jquery';

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCard,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mounted() {
    $('#generatetable').DataTable({
      processing: true,
      responsive: true,
    });
  },
  data: function () {
    return {
      products: [],
      dataParent: {
        nisn: '',
      },
      DataProduct: [],
      listSiswa: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getsiswa()
  },
  methods: {
    async getsiswa() {
      try {
        const response = await this.$http.get('/students')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    showMsgBoxTwo(id) {
      this.modalRemove = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Employee.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeParent(id)
            this.modalRemove = value
          }
          this.modalRemove = value
        })
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },

    riseUpModalRemove(val) {
      this.showMsgBoxTwo(val)
    },
    async GetAllData() {
      try {        
        const nisn = this.dataParent.nisn
        const response = await this.$http.get(`/getpaymentschools?nisn=${nisn}`)
        var DataRespone = response.data;
        var number = 1;
        var RecapTemp = [];
        $.each(DataRespone, function (i, item) {
          var DataDetail = DataRespone[i].details;
          var JsonData = {
            "no": number,
            "noinvoice": DataRespone[i].noinvoice,
            "nisn": DataRespone[i].nisn,
            "name": DataRespone[i].name,
            "jenis_bayar": DataRespone[i].jenis_bayar,
            "academic_year": DataRespone[i].academic_year,
            "periode": DataRespone[i].periode,
            "nominal_bayar": DataRespone[i].nominal_bayar,
            "status_bayar": DataRespone[i].status_bayar,
          }
          number++;
          RecapTemp.push(JsonData);
        });
        this.DataProduct = this.RecapTemp;
        $('#generatetable').DataTable().destroy();
        var myTable = $('#generatetable').DataTable({
          "paging": true,
          "lengthChange": true,
          "searching": true,
          "ordering": true,
          "info": true,
          "autoWidth": true,
          "data": [],
          "columns": [{
            "title": "No",
            "data": "no"
          },
          {
            "title": "No Invoice",
            "data": "noinvoice"
          }, {
            "title": "NISN",
            "data": "nisn"
          },
          {
            "title": "Nama",
            "data": "name"
          },
          {
            "title": "Nama Pembayaran",
            "data": "jenis_bayar"
          }, {
            "title": "Tahun Ajaran",
            "data": "academic_year"
          }, {
            "title": "Periode",
            "data": "periode"
          }, {
            "title": "Nominal",
            "data": "nominal_bayar"
          }, {
            "title": "Status",
            "data": "status_bayar"
          }],
          "columnDefs": [
            {
              targets: 7,
              render: $.fn.dataTable.render.number(',', '.', 2, '')
            }
          ]
        });
        myTable.clear();
        $.each(RecapTemp, function (index, value) {
          console.log(value);
          myTable.row.add(value);
        });
        myTable.draw();
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(this.err)
      }
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
